.yinsi{
   font-size: 14px;
   color:#333333;
   width: 100%;
   flex: 1;
   .container{
      max-width: 750px;
      margin: 0 auto;
      padding: 40px 40px;
      max-width: 1300px;
      p{
         margin: 0 0 10px;
         line-height: 30px;
         font-size: 14px;
         text-indent: 2em;
         span{
            line-height: 30px;
           font-size: 16px;
         }
         &.title{
            margin-bottom: 20px;
            text-align: center;
            white-space: normal;
            span{
               font-size: 26px;
               font-weight: 700;
               padding-top: 20px;
            }
         }
         &.sub_title{
            margin: 20px 0px;
            span{
               font-weight: 500;
            }
            
         }
      }
   }
   &.mobile{
      .container{
         p{
            margin: 0 0 20px;
            line-height: 40px;
            font-size: 28px;
            text-indent: 2em;
            span{
               line-height: 40px;
               font-size: 28px;
            }
            &.title{
               span{
                  font-size: 36px;
                  font-weight: 700;
                  padding-top: 20px;
               }
            }
         }
      }
   }
}